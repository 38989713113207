






import { Component } from 'vue-property-decorator'

import { CreationsModuleUi } from '../../../Creations/ui/Creations.ui.vue'

import { AbstractHeroAddon } from './abstract'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<CreationAddon>({
  name: 'CreationAddon',
  components: { CreationsModuleUi }
})
export class CreationAddon extends AbstractHeroAddon {}

export default CreationAddon
