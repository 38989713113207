











import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

import { benefitsBarClassRegistry, BenefitsBarProps } from './BenefitsBar.contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({ name: 'BenefitsBar' })
export class BenefitsBar extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: false, default: '' })
  private theme!: BenefitsBarProps['theme']

  /**
   * Use BenefitsBar Theme
   */
  public get themeClass (): string {
    if (!(this.theme in benefitsBarClassRegistry)) {
      return ''
    }

    return benefitsBarClassRegistry[this.theme]
  }

  public get benefits () {
    const benefits = this.siteService.getProperty('benefitBarGroup')
    if (!benefits) {
      return []
    }
    return this.filterBenefits(benefits)
  }

  public get hasBenefits (): boolean {
    return !!Object.keys(this.benefits).length
  }

  private filterBenefits (entries: AnyObject) {
    const nonEmptyEntries = Object.entries(entries).filter(([key, value]) => {
      return !!value && typeof key !== 'undefined'
    })
    return Object.fromEntries(nonEmptyEntries)
  }
}

export default BenefitsBar
