






import { Component } from 'vue-property-decorator'

import ImageUi from '../../../Image/ui/Image.ui.vue'

import { AbstractHeroAddon } from './abstract'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ImageAddon>({
  name: 'ImageAddon',
  components: { ImageUi }
})
export class ImageAddon extends AbstractHeroAddon {}

export default ImageAddon
