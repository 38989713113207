


















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { LinkProps } from './Link.contracts'
import { linkProps, useLink } from './Link.hooks'
import { themeRegistry, variantRegistry } from './Link.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const Link = defineComponent({
  name: 'Link',
  props: linkProps,
  setup (props: LinkProps) {
    return useLink(props, getCurrentInstance(), themeRegistry, variantRegistry)
  }
})

export default Link
